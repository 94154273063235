<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Edit Project <strong>{{project.project_public_id}}</strong>
          </CCardHeader>
          <CCardBody>
            <CAlert color="danger" closeButton v-if="error !== null">
              {{ error }}
            </CAlert>
            <CTabs>
              <CTab title="Loan" active>
                <CForm>
                  <CInput
                    label="Loan amount"
                    horizontal
                    plaintext
                    :value="formatAmount(project.amount_loan)"
                  />
                  <CInput
                    label="Currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Loan purpose"
                    horizontal
                    plaintext
                    :value="project.loan_purpose"
                  />
                  <CInput
                    label="Repayment type"
                    horizontal
                    plaintext
                    v-model="project.repayment_type"
                  />
                  <CInput
                    label="Monthly installment"
                    horizontal
                    plaintext
                    :value="formatAmount(project.instalment_rate)"
                  />
                  <CInput
                    label="Term"
                    horizontal
                    plaintext
                    :value="project.number_of_payments"
                  />
                  <CInput
                    label="Date created"
                    horizontal
                    plaintext
                    :value="project.created_date"
                  />
                  <CInput
                    label="Date approved"
                    horizontal
                    plaintext
                    :value="project.approved_date"
                  />
                  <CInput
                    label="Interest rate"
                    horizontal
                    plaintext
                    :value="project.interest_rate"
                  />
                  <CInput
                    label="Collateral"
                    horizontal
                    plaintext
                    :value="formatBoolean(project.collateral)"
                  />
                  <CInput
                    label="Insurance"
                    horizontal
                    plaintext
                    :value="formatBoolean(project.insurance)"
                  />
                  <div role="group" class="form-group form-row">
                    <label for="bidding_end" class="col-form-label col-sm-3"> Bidding end </label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.bidding_end"
                      placeholder="Select date"
                      id="bidding_end"
                    />
                  </div>
                  <CInput
                    label="Total repaid principal"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalRepaidPrinciple())"
                  />
                  <CInput
                    label="Total paid interest"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaidInterest())"
                  />
                  <CInput
                    label="Outstanding amount"
                    horizontal
                    plaintext
                    :value="formatAmount(getOutstandingAmount())"
                  />
                  <CSelect
                    label="Loan status"
                    horizontal
                    :options="loanStatusOptions"
                    :value.sync="project.loan_status"
                    placeholder="Please select"
                    v-model="project.loan_status"
                  />
                  <CSelect
                    label="Performance status"
                    horizontal
                    :options="performanceStatusOptions"
                    :value.sync="project.performance_status"
                    placeholder="Please select"
                    v-model="project.performance_status"
                  />
                  <div role="group" class="form-group form-row">
                    <label for="maturity_date" class="col-form-label col-sm-3"> Maturity date </label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.maturity_date"
                      placeholder="Select date"
                      id="maturity_date"
                    />
                  </div>
                  <CInput
                    label="Last payment date"
                    horizontal
                    plaintext
                    :value="getLastPaymentDate()"
                  />
                  <CInput
                    label="Next payment date"
                    horizontal
                    plaintext
                    :value="getNextPaymentDate()"
                  />
                  <CInput
                    label="Days past due"
                    horizontal
                    type="number"
                    v-model="project.days_past_due"
                  />
                  <CInput
                    label="Late category current"
                    horizontal
                    v-model="project.late_category_current"
                  />
                  <CInput
                    label="Late category worst"
                    horizontal
                    v-model="project.late_category_worst"
                  />
                  <CInput
                    label="Recovery stage"
                    horizontal
                    v-model="project.recovery_stage"
                  />
                  <div role="group" class="form-group form-row">
                    <label for="default_date" class="col-form-label col-sm-3"> Default date </label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.default_date"
                      placeholder="Select date"
                      id="default_date"
                    />
                  </div>
                  <div role="group" class="form-group form-row">
                    <label for="repaid_date" class="col-form-label col-sm-3"> Repaid date </label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.repaid_date"
                      placeholder="Select date"
                      id="repaid_date"
                    />
                  </div>
                </CForm>
              </CTab>
              <CTab title="Company">
                <CForm>
                  <CInput
                    label="Country"
                    horizontal
                    plaintext
                    :value="project.country"
                  />
                  <CSelect
                    label="Legal form"
                    horizontal
                    :options="legalFormOptions"
                    :value.sync="project.legal_form"
                    placeholder="Please select"
                    v-model="project.legal_form"
                  />
                  <CInput
                    label="Year established"
                    horizontal
                    type="number"
                    v-model="project.year_established"
                  />
                  <CInput
                    label="City"
                    horizontal
                    plaintext
                    :value="project.city"
                  />
                  <CInput
                    label="Zip code"
                    horizontal
                    plaintext
                    :value="project.zip"
                  />
                  <CInput
                    label="Sector"
                    horizontal
                    plaintext
                    :value="project.sector"
                  />
                  <CInput
                    label="Number of employees"
                    horizontal
                    type="number"
                    v-model="project.number_of_employees"
                  />
                  <CInput
                    label="Number of directors"
                    horizontal
                    type="number"
                    v-model="project.number_of_directors"
                  />
                  <CInput
                    label="Number of shareholders"
                    horizontal
                    type="number"
                    v-model="project.number_of_shareholders"
                  />
                  <CInput
                    label="Primary repayment source"
                    horizontal
                    v-model="project.primary_repayment_source"
                  />
                  <CInput
                    label="Secondary repayment source"
                    horizontal
                    v-model="project.secondary_repayment_source"
                  />
                </CForm>
              </CTab>
              <CTab title="Balance Sheet">
                <CForm>
                  <CInput
                    label="Current assets"
                    horizontal
                    type="number"
                    v-model="project.current_assets"
                    @input="updateCurrentRatio"
                  />
                  <CInput
                    label="Cash and equivalents"
                    horizontal
                    type="number"
                    v-model="project.cash_and_equivalents"
                  />
                  <CInput
                    label="Non current intangible assets"
                    horizontal
                    type="number"
                    v-model="project.non_current_intangible_assets"
                  />
                  <CInput
                    label="Non current tangible assets"
                    horizontal
                    type="number"
                    v-model="project.non_current_tangible_assets"
                  />
                  <CInput
                    label="Total assets"
                    horizontal
                    type="number"
                    v-model="project.total_assets"
                  />
                  <CInput
                    label="Current liabilities"
                    horizontal
                    type="number"
                    v-model="project.current_liabilities"
                    @input="updateCurrentRatio"
                  />
                  <CInput
                    label="Non current liabilities"
                    horizontal
                    type="number"
                    v-model="project.non_current_liabilities"
                  />
                  <CInput
                    label="Total liabilities"
                    horizontal
                    type="number"
                    v-model="project.total_liabilities"
                  />
                  <CInput
                    label="Retained earnings"
                    horizontal
                    type="number"
                    v-model="project.retained_earnings"
                  />
                  <CInput
                    label="Paid up capital"
                    horizontal
                    type="number"
                    v-model="project.paid_up_capital"
                  />
                  <CInput
                    label="Total equity"
                    horizontal
                    type="number"
                    v-model="project.total_equity"
                  />
                  <CInput
                    label="Total liabilities and equity"
                    horizontal
                    type="number"
                    v-model="project.total_liabilities_and_equity"
                  />
                  <CInput
                    label="Current ratio"
                    horizontal
                    plaintext
                    :value="formatAmount(currentRatio)"
                  />
                </CForm>
              </CTab>
              <CTab title="P&L">
                <CForm>
                  <CInput
                    label="Revenue"
                    horizontal
                    type="number"
                    v-model="project.revenue"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="COGS"
                    horizontal
                    type="number"
                    v-model="project.cogs"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Gross profit"
                    horizontal
                    plaintext
                    :value="formatAmount(grossProfit)"
                  />
                  <CInput
                    label="Operating expenses"
                    horizontal
                    type="number"
                    v-model="project.operating_expenses"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Operating income"
                    horizontal
                    plaintext
                    :value="formatAmount(operatingIncome)"
                  />
                  <CInput
                    label="Depreciation"
                    horizontal
                    type="number"
                    v-model="project.depreciation"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Financial expenses"
                    horizontal
                    type="number"
                    v-model="project.financial_expenses"
                  />
                  <CInput
                    label="Taxes"
                    horizontal
                    type="number"
                    v-model="project.taxes"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Net profit after tax"
                    horizontal
                    type="number"
                    v-model="project.net_profit_after_tax"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Revenue growth"
                    horizontal
                    type="number"
                    v-model="project.revenue_growth"
                  />
                  <CInput
                    label="Gross profit margin"
                    horizontal
                    plaintext
                    :value="formatAmount(grossProfitMargin)"
                  />
                  <CInput
                    label="EBITDA Margin"
                    horizontal
                    plaintext
                    :value="formatAmount(EBITDAMargin)"
                  />
                  <CInput
                    label="Net profit margin"
                    horizontal
                    plaintext
                    :value="formatAmount(netProfitMargin)"
                  />
                </CForm>
              </CTab>
              <CTab title="Cash Flow">
                <CForm>
                  <CInput
                    label="Cash flow operating"
                    horizontal
                    plaintext
                    :value="formatAmount(getCashFlowOperating())"
                  />
                  <CInput
                    label="Cash flow investing"
                    horizontal
                    type="number"
                    v-model="project.cash_flow_investing"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Cash flow financing"
                    horizontal
                    type="number"
                    v-model="project.cash_flow_financing"
                    @input="updateFinancialValue"
                  />
                  <CInput
                    label="Cash flow total"
                    horizontal
                    plaintext
                    :value="formatAmount(cashFlowTotal)"
                  />
                </CForm>
              </CTab>
              <CTab title="i2invest Extras">
                <CForm>
                  <h3>Investment</h3>
                  <CInput
                    label="Loan ID"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Investment ID"
                    horizontal
                    plaintext
                    v-model="project.investment_id"
                  />
                  <CInput
                    label="Loan number"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Delay of payment"
                    horizontal
                    plaintext
                    :value="project.days_past_due"
                  />
                  <CInput
                    label="Maturity date"
                    horizontal
                    plaintext
                    :value="formatDate(project.maturity_date)"
                  />
                  <CInput
                    label="Rating"
                    horizontal
                    plaintext
                    v-model="project.risk_category"
                  />
                  <CInput
                    label="Loss given default"
                    horizontal
                    plaintext
                    :value="formatAmount(getLossGivenDefault())"
                  />
                  <CInput
                    label="Country"
                    horizontal
                    plaintext
                    v-model="project.country"
                  />
                  <CInput
                    label="Loan type"
                    horizontal
                    plaintext
                    v-model="project.loan_purpose"
                  />
                  <CInput
                    label="Interest rate"
                    horizontal
                    plaintext
                    :value="project.interest_rate"
                  />
                  <CInput
                    label="Issue date"
                    horizontal
                    plaintext
                    :value="formatDate(project.bidding_end)"
                  />
                  <CInput
                    label="Loan amount"
                    horizontal
                    plaintext
                    :value="formatAmount(project.amount_loan)"
                  />
                  <CInput
                    label="My investment"
                    horizontal
                    type="number"
                    v-model="project.i2_investment"
                  />
                  <CInput
                    label="Currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Payments received"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaymentReceived())"
                  />
                  <div role="group" class="form-group form-row">
                    <label for="purchase_date" class="col-form-label col-sm-3">Purchase date</label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.purchase_date"
                      placeholder="Select date"
                      id="purchase_date"
                    />
                  </div>
                  <CInput
                    label="Loan originator"
                    horizontal
                    plaintext
                    value="Acredius"
                  />
                  <CInput
                    label="Payment status"
                    horizontal
                    plaintext
                    value="current"
                  />
                  <CInput
                    label="Contract urls"
                    horizontal
                    type="text"
                    v-model="project.contract_urls"
                  />
                  <hr/>
                  <h3>Borrower</h3>
                  <CInput
                    label="Borrower ID"
                    horizontal
                    plaintext
                    :value="project.borrower_id"
                  />
                  <CInput
                    label="City"
                    horizontal
                    plaintext
                    :value="project.city"
                  />
                  <CInput
                    label="Business sector"
                    horizontal
                    plaintext
                    :value="project.sector"
                  />
                  <hr/>
                  <h3>Payment schedule</h3>
                  <CInput
                    label="Transaction ID"
                    horizontal
                    type="text"
                    v-model="project.transaction_id"
                  />
                  <CInput
                    label="Loan ID"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Date"
                    horizontal
                    plaintext
                    :value="getTodayDate()"
                  />
                  <CInput
                    label="Principle"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalRepaidPrinciple())"
                  />
                  <CInput
                    label="Interest"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaidInterest())"
                  />
                  <CInput
                    label="Total"
                    horizontal
                    plaintext
                    :value="formatAmount(project.instalment_rate)"
                  />
                  <CInput
                    label="Payments received"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaymentReceived())"
                  />
                  <CInput
                    label="Payment date"
                    horizontal
                    plaintext
                    :value="getLastPaymentDate()"
                  />
                  <CInput
                    label="Status"
                    horizontal
                    plaintext
                    value="current"
                  />
                  <hr/>
                  <h3>Account Statement</h3>
                  <CInput
                    label="Transaction ID"
                    horizontal
                    plaintext
                    :value="project.transaction_id"
                  />
                  <CInput
                    label="Investment ID"
                    horizontal
                    plaintext
                    :value="project.investment_id"
                  />
                  <CInput
                    label="Date"
                    horizontal
                    plaintext
                    :value="getLastPaymentDate()"
                  />
                  <CSelect
                    label="Type"
                    horizontal
                    :options="accountStatementType"
                    :value.sync="project.account_statement_type"
                    placeholder="Please select"
                    v-model="project.account_statement_type"
                  />
                  <CInput
                    label="Amount"
                    horizontal
                    plaintext
                    :value="project.instalment_rate"
                  />
                  <CInput
                    label="Currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Details"
                    horizontal
                    type="text"
                    v-model="project.account_statement_details"
                  />
                  <hr/>
                  <h3>Account Summary</h3>
                  <CInput
                    label="Opening balance"
                    horizontal
                    plaintext
                    :value="formatAmount(cashFlowTotal)"
                  />
                  <CInput
                    label="Interest"
                    horizontal
                    plaintext
                    :value="project.interest_rate"
                  />
                  <CInput
                    label="Repayments"
                    horizontal
                    plaintext
                    :value="formatAmount(this.project.instalment_rate)"
                  />
                  <CInput
                    label="Investments"
                    horizontal
                    type="number"
                    v-model="project.account_summary_investments"
                  />
                  <CInput
                    label="Fees"
                    horizontal
                    type="number"
                    v-model="project.account_summary_fees"
                  />
                  <CInput
                    label="Deposits"
                    horizontal
                    plaintext
                    value="0"
                  />
                  <CInput
                    label="Withdrawals"
                    horizontal
                    plaintext
                    value="0"
                  />
                  <CInput
                    label="Closing balance"
                    horizontal
                    type="number"
                    v-model="project.account_summary_closing_balance"
                  />
                  <hr/>
                  <h3>Account Details</h3>
                  <CInput
                    label="Available cash"
                    horizontal
                    type="number"
                    v-model="project.available_cash"
                  />
                  <CInput
                    label="Available cash currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Account value"
                    horizontal
                    type="number"
                    v-model="project.account_value"
                  />
                  <CInput
                    label="Invested amount"
                    horizontal
                    type="number"
                    v-model="project.invested_amount"
                  />
                  <CInput
                    label="Number of investments"
                    horizontal
                    type="number"
                    v-model="project.number_of_investments"
                  />
                  <CInput
                    label="Loses"
                    horizontal
                    type="number"
                    v-model="project.losses"
                  />
                </CForm>
              </CTab>
              <CTab title="i2invest Extras">
                <CForm>
                  <h3>Investment</h3>
                  <CInput
                    label="Loan ID"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Investment ID"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Loan number"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Delay of payment"
                    horizontal
                    plaintext
                    :value="project.days_past_due"
                  />
                  <CInput
                    label="Maturity date"
                    horizontal
                    plaintext
                    :value="formatDate(project.maturity_date)"
                  />
                  <CInput
                    label="Rating"
                    horizontal
                    plaintext
                    v-model="project.risk_category"
                  />
                  <CInput
                    label="Loss given default"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Country"
                    horizontal
                    plaintext
                    v-model="project.country"
                  />
                  <CInput
                    label="Loan type"
                    horizontal
                    plaintext
                    v-model="project.loan_purpose"
                  />
                  <CInput
                    label="Interest rate"
                    horizontal
                    plaintext
                    :value="project.interest_rate"
                  />
                  <CInput
                    label="Issue date"
                    horizontal
                    plaintext
                    :value="formatDate(project.bidding_end)"
                  />
                  <CInput
                    label="Loan amount"
                    horizontal
                    plaintext
                    :value="formatAmount(project.amount_loan)"
                  />
                  <CInput
                    label="My investment"
                    horizontal
                    type="number"
                    v-model="project.i2_investment"
                  />
                  <CInput
                    label="Currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Payments received"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaymentReceived())"
                  />
                  <div role="group" class="form-group form-row">
                    <label for="purchase_date" class="col-form-label col-sm-3">Purchase date</label>
                    <vuejs-datepicker
                      input-class="form-control"
                      wrapper-class="col-sm-9"
                      calendar-button-icon="fa fa-calendar"
                      format="dd.MM.yyyy"
                      v-model="project.purchase_date"
                      placeholder="Select date"
                      id="purchase_date"
                    />
                  </div>
                  <CInput
                    label="Loan originator"
                    horizontal
                    plaintext
                    value="Acredius"
                  />
                  <CInput
                    label="Payment status"
                    horizontal
                    plaintext
                    value="current"
                  />
                  <CInput
                    label="Contract urls"
                    horizontal
                    type="text"
                    v-model="project.contract_urls"
                  />
                  <hr/>
                  <h3>Borrower</h3>
                  <CInput
                    label="Borrower ID"
                    horizontal
                    plaintext
                    :value="project.borrower_id"
                  />
                  <CInput
                    label="City"
                    horizontal
                    plaintext
                    :value="project.city"
                  />
                  <CInput
                    label="Business sector"
                    horizontal
                    plaintext
                    :value="project.sector"
                  />
                  <hr/>
                  <h3>Payment schedule</h3>
                  <CInput
                    label="Transaction ID"
                    horizontal
                    type="text"
                    v-model="project.transaction_id"
                  />
                  <CInput
                    label="Loan ID"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Date"
                    horizontal
                    plaintext
                    :value="getTodayDate()"
                  />
                  <CInput
                    label="Principle"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalRepaidPrinciple())"
                  />
                  <CInput
                    label="Interest"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaidInterest())"
                  />
                  <CInput
                    label="Total"
                    horizontal
                    plaintext
                    :value="formatAmount(project.instalment_rate)"
                  />
                  <CInput
                    label="Payments received"
                    horizontal
                    plaintext
                    :value="formatAmount(getTotalPaymentReceived())"
                  />
                  <CInput
                    label="Payment date"
                    horizontal
                    plaintext
                    :value="getLastPaymentDate()"
                  />
                  <CInput
                    label="Status"
                    horizontal
                    plaintext
                    value="current"
                  />
                  <hr/>
                  <h3>Account Statement</h3>
                  <CInput
                    label="Transaction ID"
                    horizontal
                    plaintext
                    :value="project.transaction_id"
                  />
                  <CInput
                    label="Investment ID"
                    horizontal
                    plaintext
                    :value="project.project_public_id"
                  />
                  <CInput
                    label="Date"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Type"
                    horizontal
                    type="text"
                    v-model="project.account_statement_type"
                  />
                  <CInput
                    label="Amount"
                    horizontal
                    plaintext
                    :value="project.instalment_rate"
                  />
                  <CInput
                    label="Currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Detail"
                    horizontal
                    type="text"
                    v-model="project.account_statement_detail"
                  />
                  <hr/>
                  <h3>Account Summary</h3>
                  <CInput
                    label="Opening balance"
                    horizontal
                    plaintext
                    :value="project.cash_flow_total"
                  />
                  <CInput
                    label="Interest"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Repayments"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Investment"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Fees"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Deposits"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Withdrawals"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <CInput
                    label="Closing balance"
                    horizontal
                    plaintext
                    value="NOT CLEAR"
                  />
                  <hr/>
                  <h3>Account Details</h3>
                  <CInput
                    label="Available cash"
                    horizontal
                    type="number"
                    v-model="project.vailable_cash"
                  />
                  <CInput
                    label="Available cash currency"
                    horizontal
                    plaintext
                    value="CHF"
                  />
                  <CInput
                    label="Account value"
                    horizontal
                    type="number"
                    v-model="project.account_value"
                  />
                  <CInput
                    label="Loses"
                    horizontal
                    type="number"
                    v-model="project.losses"
                  />
                </CForm>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter>
            <CSelect
                label="Target"
                horizontal
                :options="targetOptions"
                :value.sync="project.target"
                placeholder="Please select"
                v-model="project.target"
              />
            <div role="group" class="form-group form-row">
              <label for="api_ready" class="col-form-label col-sm-3"> API Ready? </label>
              <div class="col-sm-9">
                <CInputRadioGroup
                  :options="apiReadyOptions"
                  v-model="project.api_ready"
                  :checked="formatBoolean(project.api_ready)"
                  :inline="true"
                  @update:checked="apiReadyValueChanged"
                />
              </div>
            </div>
            <CRow><CCol md="12" class="text-right">
              <CButton type="submit" size="sm" color="primary" v-on:click="saveProject"><CIcon name="cil-check-circle"/> Submit</CButton>
            </CCol></CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'EditTypo3Project',
  data: () => {
    return {
      project: {},
      error: null,
      apiReadyOptions: ['Yes', 'No'],
      targetOptions: ['Exaloan', 'Tribe', 'i2invest'],
      loanStatusOptions: ['Funded', 'In funding', 'Cancelled'],
      performanceStatusOptions: ['On schedule', 'Late'],
      legalFormOptions: ['AG', 'Gmbh', 'SA'],
      accountStatementType: ['Principle', 'Interest'],
      currentRatio: null,
      grossProfit: null,
      operatingIncome: null,
      EBITDAMargin: null,
      grossProfitMargin: null,
      netProfitMargin: null,
      cashFlowTotal: null
    }
 },
  methods: {
    getProjectById (projectId) {
      this.error = null
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT + '/projects/typo3/' + projectId
      const header = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt')
        }
      }
      const project = axios.get(apiEndpoint, header)
      .then(({ data }) => {
        this.project = data.data.project
        this.project.repayment_type = 'Amortizing'
        this.project.collateral = 0
        this.project.insurance = 0
        this.project.country = 'Switzerland'
        // this.project.target = 'Exaloan'
        this.project.api_ready = 0
        this.project.days_past_due = 0
        this.project.current_liabilities = null
        this.project.current_assets = null
        this.project.revenue = null
        this.project.cogs = null
        this.project.current_ratio = null
        this.project.operating_expenses = null
        this.project.depreciation = null
        this.project.net_profit_after_tax = null
        this.project.taxes = null
        this.project.cash_flow_investing = null
        this.project.cash_flow_financing = null
        this.project.investment_id = this.project.uid
      })
      .catch((error) => {
        if (error.response) {
          this.error = error.response.data.message
        } else {
          this.error = error
        }
      })
    },
    saveProject () {
      if (this.project.created_date) {
        let dateParts = this.project.created_date.split('.')
        const createdDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0])
        this.project.created_date = createdDate.toISOString()
      }
      if (this.project.approved_date) {
        let dateParts = this.project.approved_date.split('.')
        const approvedDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0])
        this.project.approved_date = approvedDate.toISOString()
      }
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT+ '/projects'
      const header = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt')
        }
      }
      this.error = null
      const project = axios.post(apiEndpoint, this.project, header)
      .then(({ data }) => {
        this.$router.push({ name: 'Application Project List' })
      })
      .catch((error) => {
        if (error.response) {
          this.error = error.response.data.message
        } else {
          this.error = error
        }
      })
    },
    formatAmount (amount) {
      if (amount === 'N/A') {
        return amount
      }
      if (amount && amount !== undefined) {
        //return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2
        })
      }
      return 0
    },
    formatBoolean (value) {
      if (value !== undefined) {
        if (value === 1 || value === '1' || value === true) {
          return 'Yes'
        }
        return 'No'
      }
      return ''
    },
    getTotalRepaidPrinciple () {
      // formula: =loanAmount/term*(ROUNDDOWN((TODAY()-biddingEnd)/30))
      if (this.project.bidding_end && this.project.number_of_payments) {
        const numberOfMonthPaid = this.getNumberOfMonthPaid(this.project.bidding_end)
        return (this.project.amount_loan / this.project.number_of_payments) * numberOfMonthPaid
      }
      return 'N/A'
    },
    getTotalPaidInterest () {
      // formula: =(monthlyInstallment*term-loanAmount)/term*(ROUNDDOWN((TODAY()-biddingEnd)/30))
      if (this.project.instalment_rate && this.project.number_of_payments && this.project.bidding_end) {
        const numberOfMonthPaid = this.getNumberOfMonthPaid(this.project.bidding_end)
        return (((this.project.instalment_rate * this.project.number_of_payments) - this.project.amount_loan) / this.project.number_of_payments) * numberOfMonthPaid
      }
      return 'N/A'
    },
    getOutstandingAmount () {
      // formula: monthlyInstallment*term-(getTotalRepaidPrinciple+getTotalPaidInterest)
      if (this.project.bidding_end) {
        return (this.project.instalment_rate * this.project.number_of_payments) - (this.getTotalRepaidPrinciple() + this.getTotalPaidInterest())
      }
      return 'N/A'
    },
    getTotalPaymentReceived () {
      // formula: monthlyInstallment*term-(getTotalRepaidPrinciple+getTotalPaidInterest)
      if (this.project.bidding_end) {
        return (this.getTotalRepaidPrinciple() + this.getTotalPaidInterest())
      }
      return 'N/A'
    },
    getLastPaymentDate () {
      const todayDate = new Date()
      const currentDay = todayDate.getDate()
      const paymentDate = new Date(todayDate.setDate(22))
      if (currentDay > 21) {
        return moment(paymentDate).format('DD.MM.YYYY')
      }
      return moment(new Date(paymentDate.setMonth(paymentDate.getMonth() - 1))).format('DD.MM.YYYY')
    },
    getNextPaymentDate () {
      const todayDate = new Date()
      const currentDay = todayDate.getDate()
      const paymentDate = new Date(todayDate.setDate(22))
      if (currentDay > 21) {
        return moment(new Date(paymentDate.setMonth(paymentDate.getMonth() + 1))).format('DD.MM.YYYY')
      }
      return moment(paymentDate).format('DD.MM.YYYY')
    },
    apiReadyValueChanged (value) {
      if (value === 'No') {
        this.project.api_ready = false
      } else {
        this.project.api_ready = true
      }
    },
    getNumberOfMonthPaid(biddingEnd) {
      const todayDate = new Date()
      const biddingEndDate = new Date(this.project.bidding_end)
      const diffTime = Math.abs(todayDate - biddingEndDate)
      return Math.floor(Math.ceil(diffTime / (1000 * 60 * 60 * 24)) / 30)
    },
    updateCurrentRatio() {
      if (this.project.current_liabilities && this.project.current_assets) {
        this.project.current_ratio = this.project.current_assets / this.project.current_liabilities
        this.currentRatio = this.project.current_ratio
      }
    },
    updateFinancialValue() {
      if (!this.project.revenue) {
        this.project.revenue = 0
      }
      if (!this.project.cogs) {
        this.project.cogs = 0
      }
      if (!this.project.operating_expenses) {
        this.project.operating_expenses = 0
      }
      if (!this.project.depreciation) {
        this.project.depreciation = 0
      }
      if (!this.project.net_profit_after_tax) {
        this.project.net_profit_after_tax = 0
      }
      if (!this.project.taxes) {
        this.project.taxes = 0
      }
      if (!this.project.cash_flow_investing) {
        this.project.cash_flow_investing = 0
      }
      if (!this.project.cash_flow_financing) {
        this.project.cash_flow_financing = 0
      }
      // gross profit
      this.project.gross_profit = this.project.revenue - this.project.cogs
      this.grossProfit = this.project.gross_profit
      // operating income
      this.project.operating_income = this.project.gross_profit - this.project.operating_expenses
      this.operatingIncome = this.project.operating_income
      // gross profit margin
      if (this.project.revenue) {
        this.project.gross_profit_margin = this.project.gross_profit / this.project.revenue
        this.grossProfitMargin = this.project.gross_profit_margin
        // EBITDA margin
        // EBITDA/Revenue, (EBITDA = Operating expenseses + depreciation)
        const EBITDA = parseFloat(this.project.operating_expenses) + parseFloat(this.project.depreciation)
        this.project.ebitda_margin = EBITDA / this.project.revenue
        this.EBITDAMargin = this.project.ebitda_margin
        // net profit margin
        this.project.net_profit_margin = this.project.net_profit_after_tax / this.project.revenue
        this.netProfitMargin = this.project.net_profit_margin
      }
      // cash flow
      this.cashFlowTotal = this.getCashFlowOperating() + parseFloat(this.project.cash_flow_financing) + parseFloat(this.project.cash_flow_investing)
    },
    getCashFlowOperating() {
      if (this.operatingIncome) {
        return parseFloat(this.operatingIncome) + parseFloat(this.project.depreciation) - parseFloat(this.project.taxes)
      }
    },
    getLossGivenDefault() {
      let totalRepaidPrinciple = 0
      if (this.project.bidding_end && this.project.number_of_payments) {
        const numberOfMonthPaid = this.getNumberOfMonthPaid(this.project.bidding_end)
        totalRepaidPrinciple = (this.project.amount_loan / this.project.number_of_payments) * numberOfMonthPaid
      }
      const outstandingPrinciple = this.project.amount_loan - totalRepaidPrinciple
      return outstandingPrinciple * 0.3
    },
    formatDate(date) {
      if (date) {
        return moment(new Date(date)).format('DD.MM.YYYY')
      }
      return null
    },
    getTodayDate() {
      return moment(new Date()).format('DD.MM.YYYY')
    }
  },
  computed: {
    
  },
  beforeMount() {
    if ('projectId' in this.$route.params) {
      this.getProjectById(this.$route.params.projectId)
    }
  },
}
</script>
